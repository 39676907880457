import React, { useEffect, useState } from "react";
import { DashboardTitle, ContentFilterAction, ContentFilter, ButtonContainer, ContentTableActionIcon } from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Select from "components/Form/Select"; // Atualize o caminho conforme necessário
import { Read } from "services/btg-cash";
import { exposeStrapiError } from "utils";
import Button from "components/Form/Button";

export default function BTGCashReportApplications() {
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        assetname: [],
        operationdate: [],
        history: [],
    });

    const [filteredMinDateOptions, setFilteredMinDateOptions] = useState([]);
    const [filteredMaxDateOptions, setFilteredMaxDateOptions] = useState([]);

    const [filter, setFilter] = useState({
        assetname: '',
        minDate: '',
        maxDate: '',
        history: ''
    });

    const columns = [
        { title: 'Fundo', ref: 'assetname' },
        { title: 'Data', ref: 'operationdate' },
        { title: 'Histórico', ref: 'history' },
        { title: 'Crédito', ref: 'credit' },
        { title: 'Débito', ref: 'debt' },
        { title: 'Balance', ref: 'balance' },
    ];

    const getUniqueOptions = (data, key) => {
        return [...new Set(data.map(item => item[key]))].sort((a, b) => {
            const dateA = new Date(a.split('/').reverse().join('-'));
            const dateB = new Date(b.split('/').reverse().join('-'));
            return dateA - dateB;
        });
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const init = async () => {
        setLoading(true);
        const result = await Read();
        const data = !(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            ...item,
            operationdate: formatDate(item.operationdate)
        }));
        setRows(data);

        setFilterOptions({
            assetname: getUniqueOptions(data, 'assetname'),
            operationdate: getUniqueOptions(data, 'operationdate'),
            history: getUniqueOptions(data, 'history'),
        });

        setLoading(false);
    };

    const filterExpression = item => {
        const itemDate = new Date(item.operationdate.split('/').reverse().join('-'));
        const minDate = filter.minDate ? new Date(filter.minDate.split('/').reverse().join('-')) : null;
        const maxDate = filter.maxDate ? new Date(filter.maxDate.split('/').reverse().join('-')) : null;

        return (
            (!filter.assetname || item.assetname === filter.assetname) &&
            (!minDate || itemDate >= minDate) &&
            (!maxDate || itemDate <= maxDate) &&
            (!filter.history || item.history === filter.history)
        );
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setFilteredRows(rows.filter(filterExpression));
    }, [rows, filter]);

    useEffect(() => {
        if (filter.minDate) {
            const minDate = new Date(filter.minDate.split('/').reverse().join('-'));
            const filteredOptions = filterOptions.operationdate.filter(date => new Date(date.split('/').reverse().join('-')) >= minDate);
            setFilteredMaxDateOptions(filteredOptions);
        } else {
            setFilteredMaxDateOptions(filterOptions.operationdate);
        }
    }, [filter.minDate, filterOptions.operationdate]);

    useEffect(() => {
        if (filter.maxDate) {
            const maxDate = new Date(filter.maxDate.split('/').reverse().join('-'));
            const filteredOptions = filterOptions.operationdate.filter(date => new Date(date.split('/').reverse().join('-')) <= maxDate);
            setFilteredMinDateOptions(filteredOptions);
        } else {
            setFilteredMinDateOptions(filterOptions.operationdate);
        }
    }, [filter.maxDate, filterOptions.operationdate]);

    const clearFilters = () => {
        setFilter({
            assetname: '',
            minDate: '',
            maxDate: '',
            history: '',
        });
    };

    const escapeCSVValue = (value) => {
        if (value == null) {
            return '';
        }
        const escapedValue = value.toString().replace(/"/g, '""');
        return `"${escapedValue}"`;
    };

    const exportToCSV = async () => {
        const result = await Read();
        const data = !(result && !exposeStrapiError(result) && result.length) ? [] : result;
    
        const csvRows = [];
        const headers = columns.map(col => col.title).join(';'); // Use ponto e vírgula como separador
        csvRows.push(headers);
    
        data.forEach(row => {
            const values = columns.map(col => escapeCSVValue(row[col.ref]));
            csvRows.push(values.join(';')); // Use ponto e vírgula como separador
        });
    
        const csvString = csvRows.join('\n');
        const blob = new Blob(["\ufeff" + csvString], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'btg-cash-report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <>
            <ContainerAuthenticated page={"relatedreport/btg-cash"}>
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>BTG Caixa</DashboardTitle>

                        <ContentFilterAction>
                            <ContentFilter>
                                <Select
                                    placeholder="Fundo"
                                    options={filterOptions.assetname.map(opt => ({ id: opt, title: opt }))}
                                    value={filter.assetname}
                                    onChange={value => setFilter({ ...filter, assetname: value })}
                                />
                                <Select
                                    placeholder="Data Mínima"
                                    options={filteredMinDateOptions.map(opt => ({ id: opt, title: opt }))}
                                    value={filter.minDate}
                                    onChange={value => setFilter({ ...filter, minDate: value })}
                                />
                                <Select
                                    placeholder="Data Máxima"
                                    options={filteredMaxDateOptions.map(opt => ({ id: opt, title: opt }))}
                                    value={filter.maxDate}
                                    onChange={value => setFilter({ ...filter, maxDate: value })}
                                />
                                <Select
                                    placeholder="Histórico"
                                    options={filterOptions.history.map(opt => ({ id: opt, title: opt }))}
                                    value={filter.history}
                                    onChange={value => setFilter({ ...filter, history: value })}
                                />
                            </ContentFilter>
                            <ButtonContainer>
                                <Button primary nospace small onClick={clearFilters}>Limpar</Button>
                                <Button primary nospace small onClick={exportToCSV}>
                                    Exportar
                                    <ContentTableActionIcon />
                                </Button>
                            </ButtonContainer>
                        </ContentFilterAction>

                        <BasicTable loading={loading} columns={columns} rows={filteredRows} />
                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated>
        </>
    );
}
