import React, { useEffect, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    RowSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import { BarsChart } from "components/Form/Charts/Bars";
import { Read } from "services/indicators";
import { exposeStrapiError } from "utils";

export default function DashboardIndicators(){  

    const [columns1, setColumns1] = useState([])
    
    // const rows1 = [
    //     { active:'Renda fixa', balance:'644,94' },
    //     { active:'Renda fixa', balance:'644,94' },
    //     { active:'Renda fixa', balance:'644,94' },
    //     { active:'Renda fixa', balance:'644,94' },
    //     { active:'Renda fixa', balance:'644,94' },
    // ]


    const [columns2, setColumns2] = useState([])
    
    // const rows2 = [
    //     { exposition:'Renda fixa', balance:'644,94' },
    //     { exposition:'Renda fixa', balance:'644,94' },
    //     { exposition:'Renda fixa', balance:'644,94' },

    // ] 

    const columns3 = [
        { title:'Preço Ativo D-1', ref:'priced1' },
        { title:'D-0', ref:'priced0' }, 
    ]
    
    // const rows3 = [
    //     { priced1:'Renda fixa', priced0:'644,94' },
    //     { priced1:'Renda fixa', priced0:'644,94' },
    //     { priced1:'Renda fixa', priced0:'644,94' },
    //     { priced1:'Renda fixa', priced0:'644,94' },
    // ]

    const columns4 = [
        { title:'% Preço', ref:'price' },
        { title:'%PL', ref:'pl' }, 
    ]
    
    // const rows4 = [
    //     { price:' ', pl:'0,04%' }, 
    //     { price:'1.07%', pl:'0,04%' }, 
    //     { price:' ', pl:'0,04%' }, 
    //     { price:'1.07%', pl:'0,04%' }, 
    // ]


    const columns5 = [
        { title:'TIR', ref:'tir' },
        { title:'MOIC', ref:'mdic' },
        { title:'DPI', ref:'dpi' },
    ]
    
    // const rows5 = [
    //     { tir:'13,27%', mdic:'1,01%', dpi:'0,97' },
    //     { tir:'13,27%', mdic:'1,01%', dpi:' ' },
    //     { tir:'13,27%', mdic:'1,01%', dpi:'0,97' },
    // ] 

    const columnsEmpty = [
        { title:'*', ref:'tir' },
    ]
    
    const rowsEmpty = [
        { tir:' ' },
    ] 

    const [columns6, setColumns6] = useState([])
    
    // const rows6 = [
    //     { total:'Tx Admin \ Gestão', value:'(53,75)'},
    //     { total:'Outras despesas', value:'(6,75)'},
    //     { total:'Mov Passimo', value:' '},
    //     { total:'Total passimo', value:'(60,75)'},
    // ] 

    const [columns7, setColumns7] = useState([])
    
    // const rows7 = [
    //     { total:'(53,75)', value:'-0,30%'},
    //     { total:'(53,75)', value:'-0,30%'},
    //     { total:'(60,75)', value:'-0,30%'},
    // ] 

    const columns8 = [
        { title:'*', ref:'total' },
        { title:' ', ref:'value' },
    ]
    
    const rows8 = [
        { total:' ', value:' '},
    ]

    const columns9 = [
        { title:'PL', ref:'total' },
        { title:' ', ref:'value' },
    ]

    const columns10 = [
        { title:' ', ref:'total' },
        { title:' ', ref:'value' },
    ]

    const columns11 = [
        { title:' ', ref:'total' },
        { title:' ', ref:'value' },
    ]


    const columns12 = [
        { title:'Rentabilidade', ref:'total' },
        { title:'#', ref:'value' },
    ]
    
    // const rows12 = [
    //     { total:'Mês', value:'-0,30%'},
    //     { total:'Ano', value:'-0,30%'},
    //     { total:'Since Inception', value:'-0,30%'},
    // ]

    const columns13 = [
        { title:'Visão Cotista', ref:'total' },
    ]
    
    const rows13 = [
        { total:'Fundo'},
    ]

    const columns14 = [
        { title:'TIR', ref:'tir' },
        { title:'MOIC', ref:'mdic' },
        { title:'DPI', ref:'dpi' },
    ]
    
    // const rows14 = [
    //     { tir:'90%', mdic:'0,30%', dpi:' ' },
    // ] 


    
    const [rows1, setRows1] = useState([ ])
    const [rows2, setRows2] = useState([ ])
    const [rows3, setRows3] = useState([ ])
    const [rows4, setRows4] = useState([ ])
    const [rows5, setRows5] = useState([ ])
    const [rows6, setRows6] = useState([ ])
    const [rows7, setRows7] = useState([ ])
    const [rows12, setRows12] = useState([ ])
    const [rows14, setRows14] = useState([ ])

    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await Read()
        setColumns1([
            { title:'Ativo', ref:'active' },
            { title:`Saldo BRL (${result?.[0]?.balance || 0})`, ref:'balance' }, 
        ])

        setColumns2([
            { title:'Exposição', ref:'exposition' },
            { title:`Saldo BRL (${result?.[0]?.balance || 0})`, ref:'balance' }, 
        ])

        setColumns6([
            { title:'Total ativo', ref:'total' },
            { title:`${result?.[0]?.totalBalance || 0}`, ref:'value' },
        ])

        setColumns7([
            { title:`${result?.[0]?.totalBalance || 0}`, ref:'total' },
            { title:`${result?.[0]?.profitabilityMonth || 0}%`, ref:'value' },
        ])

        setRows1(!(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            active:'Renda fixa', balance: item.balance
        })))
        
        setRows2(!(result && result.length) ? [] : result.map(item => ({
            exposition:'Renda fixa', balance: item.balance
        })))
        setRows3(!(result && result.length) ? [] : result.map(item => ({
            priced1:'Renda fixa', priced0: item.d0
        })))
        setRows4(!(result && result.length) ? [] : result.map(item => ({
            price: item.price, pl: `${item.pl}%`
        })))
        setRows5(!(result && result.length) ? [] : result.map(item => ({
            tir: item.tir, mdic: item.moic, dpi: item.dpi
        })))
        setRows6(!(result && result.length) ? [] : [
            { total:'Tx Admin \ Gestão', value:`(0)`},
            { total:'Outras despesas', value:`(0)`},
            { total:'Mov Passimo', value:' '},
            { total:'Total passimo', value:`(${ result?.[0]?.totalPassive || 0 })`},
        ])
        setRows7(!(result && result.length) ? [] : result.map(item => ({
            total:`(${ item.totalBalance })`, value:`${ item.profitabilityMonth }%`
        })))
        setRows12(!(result && result.length) ? [] : [
            { total:'Mês', value:`${ result?.[0]?.profitabilityMonth || 0}%`},
            { total:'Ano', value:`${ result?.[0]?.profitabilityMonth || 0}%`},
            { total:'Since Inception', value:' '},
        ])
        setRows14(!(result && result.length) ? [] : result.map(item => ({
            tir: item.tir, mdic: item.moic, dpi: item.dpi
        })))
        // chamada está incorreta, deveria ser uma por categoria

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated page={"investor/indicators"}> 
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>
                            Indicadores
                        </DashboardTitle> 
                        <Row>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columns1} rows={rows1} />
                            </Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns2} rows={rows2} />
                            </Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns3} rows={rows3} />
                            </Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns4} rows={rows4} />
                            </Col>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columns5} rows={rows5} />
                            </Col>
                        </Row>
                        <RowSpacer />
                        <Row>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columns6} rows={rows6} />
                            </Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns7} rows={rows7} />
                            </Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columnsEmpty} rows={rowsEmpty} />
                            </Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns8} rows={rows8} />
                            </Col>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columnsEmpty} rows={rowsEmpty} />
                            </Col>
                        </Row>
                        <RowSpacer />
                        <Row>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columns9} rows={[]} />
                            </Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns10} rows={[]} />
                            </Col>
                            <Col sm={12} md={2}></Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns11} rows={[]} />
                            </Col>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columnsEmpty} rows={[]} />
                            </Col>
                        </Row>
                        <RowSpacer />
                        <Row>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columns12} rows={rows12} />
                            </Col>
                            <Col sm={12} md={2}></Col>
                            <Col sm={12} md={2}></Col>
                            <Col sm={12} md={2}>
                                <BasicTable loading={loading} columns={columns13} rows={rows13} />
                            </Col>
                            <Col sm={12} md={3}>
                                <BasicTable loading={loading} columns={columns14} rows={rows14} />
                            </Col>
                        </Row>
                        <RowSpacer />
                        <RowSpacer />
                        <Row> 
                            <RowSpacer />
                            <Col sm={12} md={6}>
                                <BarsChart />
                            </Col>
                            <Col sm={12} md={6}>
                                <BarsChart />
                            </Col> 
                        </Row>
                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}