import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;






export const PoolLabels = styled.div.attrs({ 
})`             
    padding-top: 122px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
`;

export const LabelContentOutShadow = styled.div.attrs({ 
})`             
    box-shadow: 0px 3px 6px rgba(0,0,0,.1);
    border-radius: 6px;
`;

export const LabelContentOut = styled.div.attrs({ 
})`             
    overflow: hidden;
    border-radius: 6px;
`;

export const LabelContent = styled.div.attrs({ 
})`             
    display: flex;
    box-shadow: 0px 3px 6px rgba(0,0,0,.1);
`;

export const LabelTitle = styled.div.attrs({ 
})`             
    background: ${ props => props.theme.palette.colors.blue };
    color: ${ props => props.theme.palette.colors.white };
    padding: 6px 12px; 
`;
    
export const LabelInfo = styled.div.attrs({ 
})`             
    background: ${ props => props.theme.palette.colors.background };
    padding: 6px 12px; 
`;

export const ContentFilter = styled.div.attrs({ 
})`            
    max-width: 320px;
    margin-bottom: 32px;
`;
 

export const ContentFilterAction = styled.div.attrs({ 
})`            
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`;

export const ContentTableAction = styled.div.attrs({ 
})`            
    max-width: 180px;
    min-width: 160px; 
`;

export const ContentTableActionIcon = styled.img.attrs({ 
    src: '/icons/upload.svg',
    width: 12
})`          
    margin-left: 10px;   
`;