import React, { useContext, useEffect } from "react";

import Header from 'components/Dashboard/Header'

import {
    DashboardPage,
    DashboardBody,
    DashboardBodyContent,
    Content
} from "./styled";
import { ReadObject } from "services/storage";
import { useHistory } from "react-router-dom";
import { ThemedComponent } from "ui/theme";
import DashboardSide from "components/Dashboard/Side";
import { CoreContext } from "context/CoreContext.js";

export default function ContainerTrackQuotesRelease({ children, page, print }) {

    const { user } = useContext(CoreContext)

    return (
        <>
            <ThemedComponent>
                <Content>
                    <DashboardPage>
                        {print ? null : <Header />}
                        <DashboardBody>
                            {user && user.id ? (print ? null : <DashboardSide opened={true} page={page} />) : null}

                            <DashboardBodyContent>
                                {children}
                            </DashboardBodyContent>
                        </DashboardBody>
                    </DashboardPage>
                </Content>
            </ThemedComponent>
        </>
    );
}