import { GET, PUT, POST, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/users`, params, true);
}

export const Read = async () => {
    return await GET(`/users`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/users/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/users/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/users/${id}`, true);
}

export const ResetPassword = async (params) => {
    return await PUT(`/v1/me/password`, params, true);
}
