import { useContext, useEffect, useState } from "react";

import {
    DashboardTitle,
    DashboardText,

    PoolLabels,
    LabelContent,
    LabelTitle,
    LabelInfo,
    LabelContentOut,
    LabelContentOutShadow,
    ContentFilter,
    ContentTableAction,
    ContentTableActionIcon,
    ContentFilterAction, PoolLabelsSpacer,
    Wrapper
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import { ComboChart } from "components/Form/Charts/Combo";

import { Read as ReadV } from "services/cad-vehicles";

import { exposeStrapiError, parseCurrency } from "utils";
import { vwAplicacoesExportPdf, VwApplicationFLQuarterly, VwApplicationIndicators } from "services/vw";
import { CoreContext } from "context/CoreContext";
import Select from "components/Form/Select";
import useQuote from "hooks/useQuote";
import Button from "components/Form/Button";
import { API_ENDPOINT } from "services/api";
import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";

export default function DashboardApplications() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { selectedQuote } = useContext(CoreContext)
    console.log("🚀 ~ DashboardApplications ~ selectedQuote:", selectedQuote)

    const { userQuotes } = useQuote()

    const dataHeader = [
        "Trimestre",
        "Amortizado",
        "Aplicação",
        "Acumulado",
    ]
    const [data, setData] = useState(null);

    const columns = [
        { title: 'Trimestre', ref: 'quarter' },
        { title: 'Amortizado', ref: 'amortization' },
        { title: 'Aplicação', ref: 'application' },
        { title: 'Acumulado', ref: 'accumulation' },
    ]

    const [labels, setLabels] = useState([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)

    const [vehicles, setVehicles] = useState([])
    console.log("🚀 ~ DashboardApplications ~ vehicles:", vehicles)
    const [filterVehicles, setFilterVehicles] = useState(null)
    console.log("🚀 ~ DashboardApplications ~ filterVehicles:", filterVehicles)

    const [fullResult, setFullResult] = useState(null)

    const init = async () => {
        setLoading(true)

        const result = await VwApplicationFLQuarterly()
        // console.log('VwApplicationFLQuarterly', result)

        const resultGraph = await VwApplicationIndicators()
        // console.log('VwApplicationIndicators', resultGraph)

        const resultVehicles = await ReadV()
        // console.log('ReadVehicles', resultVehicles, userQuotes)
        setVehicles(resultVehicles.filter(fit => userQuotes?.map(mit => mit.id_veiculo).includes(fit.id_origem)))

        setFullResult({
            result: result?.length ?
                result
                    ?.map(m => ({ ...m, trimestre: `${m.trimestre.substr(-1)}T` }))
                    ?.map(m => ({ ...m, quarter: `${m?.trimestre}/${m?.ano}` }))
                    ?.sort((a, b) => clearQuarter(a.quarter) - clearQuarter(b.quarter))
                : result,
            resultGraph,
            resultVehicles
        })
    }

    const filterQuote = fit => {
        console.log("🚀 ~ filterQuote ~ fit:", JSON.stringify(fit, null, 2))

        console.log('selectedQuote?.id_origem', selectedQuote?.id_origem)
        console.log('fit.id_cotista', fit.id_cotista)

        return (selectedQuote?.id_origem && fit.id_cotista === selectedQuote?.id_origem);
    }


    const injectEmpty = (arr) => {
        if (arr.length) {
            const result = []
            for (let i = 0; i < arr.length; i++) {
                const current = { ...arr[i] }
                result.push(current)
                const quartersOnYear = arr.filter(f => f.ano === current.ano)
                const pretendQuarters = [1, 2, 3, 4]
                pretendQuarters.forEach(item => {
                    if (!quartersOnYear.map(m => `${m.trimestre}`).includes(`${item}T`)) {


                        // dentro do ano deste item, está faltando um trimestre
                        const mayNext = { quarter: `${item}T/${current.ano}`, trimestre: `${item}T`, ano: `${current.ano}`, amortizacao: "0", aplicacao: "0", acumulado: "0", empty: true }
                        const quarterDate = new Date(current.ano, (item * 3), 1)
                        quarterDate.setDate(quarterDate.getDate() - 1)
                        const now = new Date()

                        // console.log(mayNext.quarter, quarterDate, now, (now.getTime() > quarterDate.getTime()) )

                        if (!result?.filter(f => f.trimestre === mayNext.trimestre && f.ano === mayNext.ano).length && (now.getTime() > quarterDate.getTime())) {

                            // console.log("Founded missing item on yeaR", current.ano, current.trimestre, item, current)

                            // next quarter empty. including ... else already included from some previous item
                            // console.log(mayNext)
                            result.push(mayNext)
                        }
                    }
                })
            }
            return (result?.sort((a, b) => clearQuarter(a.quarter) - clearQuarter(b.quarter)))
        }
        return (arr)
    }

    const zeroPrevMonth = (arr) => {
        // return arr;
        // console.log('Z')
        const narr = arr.map((item, key) => {

            if (item.amortizacao == "0" && item.aplicacao == "0" && item.acumulado == "0") {
                const value2Assing = findPrevValue(arr, key)
                // console.log("ZPF", item?.quarter, value2Assing?.quarter, value2Assing?.acumulado)
                return {
                    ...item,
                    amortizacao: parseFloat(value2Assing?.amortizacao).toFixed(2),
                    aplicacao: parseFloat(value2Assing?.aplicacao).toFixed(2),
                    acumulado: parseFloat(value2Assing?.acumulado).toFixed(2),
                }
            }

            return item
        }) || []

        return narr
    }

    const findPrevValue = (arr, key) => {
        if (key <= 0) {
            return arr[0]
        } else {
            if (arr[key - 1].acumulado !== "0") {
                return arr[key - 1]
            } else {
                return findPrevValue(arr, key - 1)
            }
        }
    }

    const printView = () => {
        setLoading(true)

        const origin = vehicles?.find(fnd => fnd.id === filterVehicles)?.id_origem ?? null
        // console.log('PRINT VIEW', fullResult?.result, fullResult?.resultGraph, origin, selectedQuote)
        // console.log({ filterVehicles, origin })

        // const result = injectEmpty(( origin ? fullResult?.result?.filter( fit => fit?.id_veiculo == origin ) : fullResult?.result ).filter(filterQuote))
        const result = (origin ? fullResult?.result?.filter(fit => fit?.id_veiculo == origin) : fullResult?.result).filter(filterQuote)
        const nonEmptyResult = zeroPrevMonth((injectEmpty((origin ? fullResult?.result?.filter(fit => fit?.id_veiculo == origin) : fullResult?.result).filter(filterQuote)) || [])?.reduce(reduceEqualsChart, []))
        const resultGraph = (origin ? fullResult?.resultGraph?.filter(fit => fit?.id_veiculo == origin) : fullResult?.resultGraph).filter(filterQuote)

        // console.log("nonEmptyResult", nonEmptyResult)
        // console.log({ result, resultGraph })

        const preRows = !(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            quarter: `${item?.trimestre}/${item?.ano}`,
            amortization: parseCurrency(item?.amortizacao),
            application: parseCurrency(item?.aplicacao),
            accumulation: parseCurrency(item?.acumulado),
        }))

        setRows(preRows)

        const nextData = [dataHeader, ...(!(nonEmptyResult && nonEmptyResult.length) ? [] : nonEmptyResult?.map(item => [`${item?.trimestre}/${item?.ano}`, parseFloat(item?.amortizacao || 0), (parseFloat(item?.aplicacao || 0) * -1), parseFloat(item?.acumulado || 0)]))]
        // console.log('nextData', nextData)
        setData(nextData?.length === 1 ? [...nextData, ['', 0, 0, 0]] : nextData)

        const reduceSum = (p, c) => p + (c || 0)

        const totalApplicated = resultGraph?.map(mit => parseFloat(mit?.total_aplicado))?.reduce(reduceSum, 0)
        const totalAmortized = resultGraph?.map(mit => parseFloat(mit?.total_amortizado))?.reduce(reduceSum, 0)
        const totalCommitment = resultGraph?.map(mit => parseFloat(mit?.compromisso))?.reduce(reduceSum, 0)

        // const totalIntegralizado = resultGraph?.map( mit => parseFloat(mit?.integralizado) * 100 )?.reduce(reduceSum, 0 )
        // const totalDpi = resultGraph?.map( mit => parseFloat(mit?.dpi) )?.reduce(reduceSum, 0 )

        const totalIntegralizado = ((totalApplicated / totalCommitment) * 100)
        const totalDpi = (totalAmortized / totalApplicated)

        setLabels(!(result && result.length) ? [] : [
            { title: 'Total aplicado', value: `${parseCurrency(totalApplicated)}` },
            { title: 'Total amortizado', value: `${parseCurrency(totalAmortized)}` },
            { title: 'Compromisso', value: `${parseCurrency(totalCommitment)}` },
            { title: 'Integralizado', value: `${(totalIntegralizado)?.toFixed(1)}%` },
            { title: 'DPI', value: `${parseCurrency(totalDpi)}` },
        ])
        setLoading(false)
    }

    const exportExcel = async () => {
        const origin = vehicles?.find(fnd => fnd.id === filterVehicles)?.id_origem ?? null
        // console.log(`${ API_ENDPOINT }/csv/download?filter={"id_veiculo":${ origin }}&omit=["created_at", "updated_at", "created_by", "updated_by", "id"]&table=vw-aplicacoes-fl-trimestral`, 'Fluxo de caixa') 
        if (selectedQuote?.id_origem) {
            window.open(`${API_ENDPOINT}/csv/download?filter={"id_veiculo":${origin}, "id_cotista":${selectedQuote?.id_origem}}&omit=["created_at", "updated_at", "created_by", "updated_by", "id"]&table=vw-aplicacoes-fl-trimestral`, 'Fluxo de caixa')
        } else {
            window.open(`${API_ENDPOINT}/csv/download?filter={"id_veiculo":${origin}}&omit=["created_at", "updated_at", "created_by", "updated_by", "id"]&table=vw-aplicacoes-fl-trimestral`, 'Fluxo de caixa')
        }
    }

    const exportPdfHandler = async () => {
        setLoading(true);
        const idCotista = selectedQuote?.id_origem;
        const id = filterVehicles
        console.log("🚀 ~ exportPdfHandler ~ id:", id)
        const estrutura = vehicles.find(fnd => fnd.id === id)?.estrutura || 'Geral';

        // Coletando os dados necessários para o PDF
        const pdfData = {
            id_cotista: idCotista,
            fundo: estrutura,
            rows,
            labels,
            chartData: data
        };

        console.log('PDF Data:', pdfData);

        try {
            const response = await vwAplicacoesExportPdf(pdfData);
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'fluxo_de_caixa.pdf');
            document.body.appendChild(link);
            link.click();
            setLoading(false);
        } catch (err) {
            setLoading(false);
            console.error('Erro ao gerar PDF:', err);
        }
    };


    const clearQuarter = quarter => {
        return parseInt(`${quarter || ""}`.split("T/")?.[1] + `${quarter || ""}`.split("T/")?.[0])
    }

    const sortQuarter = (a, b) => {
        return clearQuarter(b.quarter) - clearQuarter(a.quarter)
    }

    const clearFloat = string => {
        return typeof string === 'string' ? parseFloat(string.replace(/\./g, '').replace(',', '.')) : string
    }

    const sumObj = (p, c) => {
        let obj = {}
        Object.keys(p).map((i, k) => {
            obj[i] = (i === 'quarter') ? p[i] : parseCurrency(clearFloat(p[i] || '0') + clearFloat(c[i] || '0'))
        })
        return obj
    }

    const sumEquals = (p, c) => {
        return p.reduce((m, n) => sumObj(m, n), c)
    }

    const reduceEquals = (p, c) => {
        const n = (p.map(m => m.quarter).includes(c.quarter) ?
            [...p.filter(m => m.quarter !== c.quarter), sumEquals(p.filter(m => m.quarter === c.quarter), c)]
            : [...p, c])
        return n
    }

    const sumObjChart = (p, c) => {
        let obj = {}
        Object.keys(p).map((i, k) => {
            obj[i] = (
                i === 'id'
                || i === 'id_cotista'
                || i === 'id_origin'
                || i === 'id_veiculo'
                || i === 'created_at'
                || i === 'updated_at'
                || i === 'quarter'
                || i === 'ano'
                || i === 'trimestre'
            ) ? p[i] : `${(parseFloat(p[i] || '0') + parseFloat(c[i] || '0'))}`
        })
        return obj
    }

    const sumEqualsChart = (p, c) => {
        return p.reduce((m, n) => sumObjChart(m, n), c)
    }

    const reduceEqualsChart = (p, c) => {
        const n = (p.map(m => m.quarter).includes(c.quarter) ?
            [...p.filter(m => m.quarter !== c.quarter), sumEqualsChart(p.filter(m => m.quarter === c.quarter), c)]
            : [...p, c])
        return n
    }

    const takeCanvas = (id) => {
        return new Promise((resolve, reject) => {
            var contentDiv = document.getElementById(id);
            html2canvas(contentDiv, {
                width: contentDiv.offsetWidth * 1.7,
                height: contentDiv.offsetHeight * 1.3,
            }).then(function (canvas) {
                resolve({
                    canvas: canvas.toDataURL('image/jpeg'),
                    width: contentDiv.offsetWidth,
                    height: contentDiv.offsetHeight
                })
                // resolve(canvas) 
            });
        })
    }

    const print = async () => {
        const headerData = await takeCanvas('printable-header')
        const bodyData = await takeCanvas('printable-content')
        printWindow(headerData, bodyData)
    }

    const printWindow = (headerData, bodyData) => {
        var printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write('<html><head><title>Spectra</title></head><body><div id="div" style="display:flex;flex-direction:column;align-items:center; gap: 24px;">');
        printWindow.document.write('<img style="margin:0 auto -32px 0" src="/logo1024.png" width="256" onload="window.print();window.close();" />');
        printWindow.document.write('<div style="display:block; width:' + headerData.width + 'px; height:' + headerData.height + 'px; overflow:hidden"><img style="margin:0" src="' + headerData.canvas + '" /></div>');
        printWindow.document.write('<div style="display:block; width:' + bodyData.width + 'px; height:' + bodyData.height + 'px; overflow:hidden"><img style="margin:0" src="' + bodyData.canvas + '"  /></div>');
        printWindow.document.write('</div></body></html>');
        printWindow.document.close();
    }

    useEffect(() => {
        if (userQuotes?.length) { init(); }
    }, [userQuotes])

    useEffect(() => {
        if (fullResult?.result && fullResult?.resultGraph && filterVehicles) {
            printView()
        }
    }, [fullResult, filterVehicles])

    return (
        <>
            <ContainerAuthenticated page={"investor/applications"}>
                <Row>
                    <Col sm={12} md={6}>
                        <DashboardTitle> Fluxo de caixa </DashboardTitle>
                        {!selectedQuote?.id_origem ? null : <DashboardText> Cotista: {selectedQuote?.title ? (selectedQuote?.title || '') : `Spectra`} </DashboardText>}

                        <ContentFilterAction>
                            <ContentFilter>
                                <Select placeholder={"Fundos (Veículos)"} options={[...vehicles?.map(item => ({ ...item, title: item.veiculo })), { title: "Geral", id: "general" }]} value={filterVehicles} onChange={setFilterVehicles} />
                            </ContentFilter>
                            <ContentTableAction>
                                {
                                    !filterVehicles ? null : <Wrapper>
                                        <Button primary nospace small onClick={() => exportExcel()}>
                                            Exportar
                                            <ContentTableActionIcon />
                                        </Button>
                                        <Button primary nospace small onClick={() => exportPdfHandler()}>
                                            PDF
                                            <ContentTableActionIcon />
                                        </Button>
                                    </Wrapper>
                                }
                            </ContentTableAction>
                        </ContentFilterAction>

                        <DashboardText> &nbsp; </DashboardText>

                        <div id="printable-content">
                            {filterVehicles && <BasicTable columns={columns} rows={rows?.reduce(reduceEquals, []).sort(sortQuarter)} loading={loading} />}
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <PoolLabelsSpacer />
                        <div id="printable-header">
                            <PoolLabels>
                                {
                                    labels.map((item, key) =>
                                        <LabelContentOutShadow key={key}>
                                            <LabelContentOut>
                                                <LabelContent>
                                                    <LabelTitle>{item.title}</LabelTitle>
                                                    <LabelInfo>{item.value}</LabelInfo>
                                                </LabelContent>
                                            </LabelContentOut>
                                        </LabelContentOutShadow>
                                    )
                                }
                            </PoolLabels>
                            {data && <ComboChart infos={data} />}
                        </div>

                    </Col>
                </Row>
            </ContainerAuthenticated>
        </>
    );
}