import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DoLogout } from "services/authentication";

import {
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    DashboardHeaderActionLogo,
    ContentFilter,
    LastUpdate,
    TextUpdate
} from "./styled";
import Autocomplete from "components/Form/Autocomplete";
import { CoreContext } from "context/CoreContext";
import Hotjar from "@hotjar/browser";
import { Read } from "services/application-settings";
import { formatDateTime } from "utils";
import moment from "moment";

export default function DashboardHeader() {
    const [latestUpdate, setLatestUpdate] = useState(null);
    console.log("🚀 ~ DashboardHeader ~ latestUpdate:", latestUpdate)

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { user, setModal, selectedQuote, setSelectedQuote } = useContext(CoreContext)

    console.log(user);

    const quotes = user?.cotistas?.length ? user?.cotistas : [];

    const exit = async () => {
        try {
            if (user && user.id) {
                Hotjar.identify(user.id.toString(), {
                    email: user.email,
                    username: user.username
                });

                const loginTimestamp = parseInt(localStorage.getItem('loginTimestamp'), 10);
                const logoutTimestamp = new Date().getTime();
                const sessionDuration = logoutTimestamp - loginTimestamp;
                const sessionDurationMinutes = Math.round(sessionDuration / 60000);

                Hotjar.event('session_duration', { duration: sessionDurationMinutes });
            }
        } catch (error) {
            console.error("Hotjar tracking failed", error);
        }

        localStorage.removeItem('loginTimestamp');
        await DoLogout();
        navigate('login');
    };

    const fetchLatestUpdate = async () => {
        try {
            const result = await Read();
            console.log("🚀 ~ fetchLatestUpdate ~ result:", result)
            setLatestUpdate(result?.dataBase);
        } catch (error) {
            console.log("🚀 ~ fetchLatestUpdate ~ error:", JSON.stringify(error, null, 2))
        }
    };

    useEffect(() => {
        fetchLatestUpdate();
    }, []);

    // console.log('user ', user);

    return (
        <>
            <DashboardHeaderContainer>
                <DashboardHeaderAction>
                    <DashboardHeaderActionLogo />
                </DashboardHeaderAction>

                {user && user.id ? (
                    <>
                        <LastUpdate>
                            <TextUpdate>Data base: {latestUpdate ? moment(latestUpdate).format("DD/MM/YYYY") : ''}</TextUpdate>
                        </LastUpdate>

                        <ContentFilter>
                            <Autocomplete color={"white"} placeholder={"Cotista"} options={quotes.map(item => ({ ...item, title: item.nome_cotista }))} value={selectedQuote} onChange={setSelectedQuote} />
                        </ContentFilter>

                        <DashboardHeaderAction button onClick={() => setModal({ type: 'pdf', pdf: "/assets/manual-sistema-frontend.pdf" })}>
                            Manual
                        </DashboardHeaderAction>

                        <DashboardHeaderAction button onClick={exit}>
                            <DashboardHeaderActionIcon src={`/icons/door-close.svg`} alt="logout-icon" />
                            Sair
                        </DashboardHeaderAction>
                    </>
                ) : null}

            </DashboardHeaderContainer>
        </>
    );
}
