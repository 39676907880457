import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CoreContext } from 'context/CoreContext'

import {
    FormTitle,
    FormText, 

    UpIcon,
    ModalBody,
    LoadContent,
    Load,
    FormActions,
    ModalHeader,
    ModalHeaderClose,
    ContentUpload,
    ContentUploadIcon,
    ContentUploadText,
    ContentUploadIconStatus,
    ContentTableActionIconFileSuccess,
    FormContentActions,
    FormRow,
    FormRowInput,
    ContentSteps,
    StepItem,
    StepItemText,
    StepItemDecoration,

    QuotesWrapper,
    QuotesItem,
    QuotesRemove,
    QuotesRemoveIcon
} from './styled'

import Button from 'components/Form/Button'
import ModalWrapper from 'components/Modal/ModalWrapper'
import { ButtonIcon } from 'ui/styled'
import Select from 'components/Form/Select'
import Input from 'components/Form/Input'
import Radio from 'components/Form/Radio'
import Check from 'components/Form/Check'
import { Create, Update } from 'services/users'
import { Read } from 'services/cad-quotes'
import { exposeStrapiError } from 'utils'

export default function ModalUser(){

    const { modal, setModal } = useContext(CoreContext)
      
    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState( modal?.user?.name ?? '')
    const [email, setEmail] = useState(modal?.user?.email ?? '')
    const [cotistas, setCotistas] = useState(modal?.user?.cotistas?.map(mm => mm.id) ?? [])
    const [spectra, setSpectra] = useState(modal?.user ? modal?.user.spectra ? 2 : 1 : false)
    
    const [cotista, setCotista] = useState(null)
    // console.log('modal?.user?.cotistas', modal?.user?.cotistas)
    console.log('modal?.user', modal?.user)
 
    const reportOptions = [
        { id:1, title:'Cotista' },
        { id:3, title:'Cotista sem Fluxo de Caixa' },
        { id:2, title:'Spectra' },
    ]

    const [cadCotistaOptions, setCadCotistaOptions] = useState([
        { id:1, title:'Cotista 1' },
        { id:2, title:'Cotista 2' },
    ])

    const close = () => {
        setModal(null)
    } 

    const complete = async () => { 
        setLoading(true)

        const maybeOrigin = spectra === 2 ? {} : {
            // idOrigin,
            // cotistas,
            cotistas: cotistas?.map(mm => parseInt(mm)),
            // cotistas: cadCotistaOptions.filter( mm => cotistas.includes(`${mm.id}`) )
        }

        const payload = modal?.user?.id ? {
            name, username:email, email, spectra: spectra === 2, icotista: spectra === 3, ...maybeOrigin
        } : {
            name, username:email, email, spectra: spectra === 2, icotista: spectra === 3, password: (new Date().getTime()), ...maybeOrigin
        }
        const result = modal?.user?.id ? await Update(payload, modal.user.id) : await Create(payload)
        if(result && !exposeStrapiError(result)){
            setSuccess(true)
        }
        setLoading(false)
    }
    
    const next = () => { 
        complete() 
    }

    const back = () => { 
        close() 
    }

    const isCotista = item => {
        return cotistas.includes(item)
    }

    const toggleCotistas = item => {
        console.log('pretend to add ', item , cotistas, !isCotista(item), [ ...cotistas, item ])
        setCotistas( !isCotista(item) ? [ ...cotistas, item ] : [ ...cotistas.filter(fit => fit != item) ] )
    }

    const init = async () => {
        const result = await Read()
        if(result && !exposeStrapiError(result)){
            setCadCotistaOptions(result?.map(mit => ({ ...mit, title:mit.nome_cotista, id: mit.id }) ))
        }
    }

    useEffect(() => {
        init()
    }, [])

    return (
        <>
            <ModalWrapper>
                <ModalHeader success={success}>
                    { modal?.user?.id ? 'Editar' : 'Novo' } usuário
                    <ModalHeaderClose success={success} onClick={close}>
                        x
                    </ModalHeaderClose>
                </ModalHeader>
                {
                    success ? 
                        <ModalBody> 
                            <ContentTableActionIconFileSuccess /> 
                            {
                                modal?.user?.id ? <>
                                    <FormTitle>
                                        Usuário editado com sucesso
                                    </FormTitle>
                                </> :<>
                                    <FormTitle>
                                        Novo usuário criado com sucesso
                                    </FormTitle>
                                    <FormTitle>
                                        Acompanhe o processo do cadastro através do status. 
                                    </FormTitle>
                                </>
                            }
                            <FormContentActions>
                                <Button primary onClick={close}> Ok </Button>
                            </FormContentActions>
                        </ModalBody>
                     :  
                        <ModalBody> 
                            
                            
                            <FormRowInput>
                                <Input placeholder={"Nome"} label={"Nome"} value={name} onChange={e => setName(e.target.value)} />
                            </FormRowInput>
                            {/* <FormRowInput>
                                <Input placeholder={"Sobrenome"} label={"Sobrenome"} />
                            </FormRowInput> */}
                            <FormRowInput>
                                <Input placeholder={"E-mail"} label={"E-mail"} value={email} onChange={e => setEmail(e.target.value)} />
                            </FormRowInput> 
                            {/* <FormRowInput>
                                <Input placeholder={"CPF"} label={"CPF"} />
                            </FormRowInput>  */}
                            <FormRowInput>
                                <Select outline label={"Perfil"} placeholder={"Perfil"} options={reportOptions} value={spectra} onChange={e => setSpectra(e)} />
                            </FormRowInput>
                            {
                                spectra === 1 || spectra === 3 ? 
                                <FormRowInput>
                                        {/* <Input placeholder={"Cad_cotista"} label={"Cad_cotista"} value={idOrigin} onChange={e => setIdOrigin(e.target.value)} /> */}
                                        {/* <Select outline label={"Cad_cotista"} placeholder={"Cad_cotista"} options={cadCotistaOptions} value={idOrigin} onChange={e => setIdOrigin(e)} /> */}
                                        <Select outline label={"Cad_cotista"} placeholder={"Cad_cotista"} options={cadCotistaOptions?.filter(fit => !isCotista(fit.id) )} onChange={e => toggleCotistas(e)} />
                                    </FormRowInput> 
                                : null
                            }   

                            <QuotesWrapper>
                                {
                                    cadCotistaOptions?.filter(fit => isCotista(fit.id) )?.map((item, key) => 
                                        <QuotesItem key={key}>
                                            { item.title }
                                            <QuotesRemove onClick={() => toggleCotistas(item.id)}>
                                                <QuotesRemoveIcon />
                                            </QuotesRemove>
                                        </QuotesItem>
                                    )
                                }
                            </QuotesWrapper>

                            <FormActions>
                                <Button secondary small onClick={back}>Cancelar</Button>
                                <Button small primary loading={loading} onClick={() => next()}>Continuar</Button>
                            </FormActions>
                        </ModalBody>
                }
            </ModalWrapper>
        </>
    )
}