import React, { useContext, useState } from 'react';
import Input from "components/Form/Input";
import { Form } from "./styled";
import Button from "components/Form/Button";
import { toast } from "react-toastify";
import { exposeStrapiError } from "utils";
import { Update } from "services/users"; // Utilize a função PostImage para upload de imagem
import { CoreContext } from 'context/CoreContext.js';
import { PostImage } from 'services/api.js'; // Certifique-se de que o caminho esteja correto
import { AvatarUpload } from 'components/Form/UploadAvatar/index.js';

export default function FormUpdateData() {
  const { user, setUser } = useContext(CoreContext);
  const [username, setUsername] = useState(user.username || "");
  const [email, setEmail] = useState(user.email || "");
  const [name, setName] = useState(user.name || "");
  const [loading, setLoading] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);

  const handleFileSelect = (file) => {
    setAvatarFile(file);
  };

  const handleInputChange = (e, ref) => {
    switch (ref) {
      case 'username':
        setUsername(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'name':
        setName(e.target.value);
        break;
      default:
        break;
    }
  }

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    let imageId = user.image?.id || null;
    let uploadedImage = null;

    // Upload image if a new file is selected
    if (avatarFile) {
      const uploadResponse = await PostImage(avatarFile);
      if (!exposeStrapiError(uploadResponse)) {
        imageId = uploadResponse[0].id;
        uploadedImage = uploadResponse[0];
      } else {
        toast("Ocorreu um erro ao fazer upload da imagem.");
        setLoading(false);
        return;
      }
    }

    const userData = {
      username: username || user.username,
      email: email || user.email,
      name: name || user.name,
      image: imageId
    };

    const response = await Update(userData, user.id);
    if (!exposeStrapiError(response)) {
      const updatedUser = {
        ...user,
        username: username || user.username,
        email: email || user.email,
        name: name || user.name,
        image: uploadedImage || user.image // Use the uploaded image response
      };
      setUser(updatedUser);
      toast("Dados alterados com sucesso!");
    } else {
      toast("Ocorreu um erro ao alterar os dados.");
    }
    setLoading(false);
  }

  const inputFields = [
    { placeholder: "Nome de usuário", label: "", value: username, ref: 'username' },
    { placeholder: "Email", label: "", value: email, ref: 'email' },
    { placeholder: "Nome completo", label: "", value: name, ref: 'name' },
  ];

  return (
    <Form onSubmit={handleSubmit}>
      <AvatarUpload onFileSelect={handleFileSelect} currentAvatar={user.image?.url} />
      {inputFields.map((field, index) => (
        <Input
          key={index}
          placeholder={field.placeholder}
          label={field.label}
          onChange={(event) => handleInputChange(event, field.ref)}
          type="text"
          value={field.value}
        />
      ))}
      <Button type="submit" loading={loading} primary onClick={handleSubmit}>Salvar</Button>
    </Form>
  )
}
