import React, { useContext, useState } from 'react'
import { CoreContext } from 'context/CoreContext'

import {
    SideHeader,
    SideHeaderText,
    SideHeaderIcon,

    NotificationItem,
    NotificationTitle,
    NotificationSubtitle,
    NotificationText
} from './styled'

import Button from 'components/Form/Button'
import ModalWrapper from 'components/Modal/ModalWrapper'
import { ButtonIcon } from 'ui/styled'

export default function ModalNotification(){

    const { modal, setModal } = useContext(CoreContext) 

    const close = () => {
        setModal(null)
    }

    const action = () => { 
        if(modal?.action?.action && typeof modal?.action?.action === 'function'){
            modal?.action?.action()
        } 
        setModal(null) 
    }

    return (
        <>
            <ModalWrapper side>
                <SideHeader>
                    <SideHeaderText>
                        Notificações
                    </SideHeaderText>
                    <SideHeaderIcon onClick={close} />
                </SideHeader>

                {
                    [
                        {
                            title:'12/04/2022 às 13:44',
                            subtitle:'Título da notificação',
                            text:'Conteúdo.'
                        },
                        {
                            title:'12/04/2022 às 13:44',
                            subtitle:'Título da notificação',
                            text:'Conteúdo.'
                        }
                    ].map((item, key) => 
                        <NotificationItem key={key} onClick={action}>
                            <NotificationTitle>{ item.title }</NotificationTitle>
                            <NotificationSubtitle>{ item.subtitle }</NotificationSubtitle>
                            <NotificationText>{ item.text }</NotificationText>
                        </NotificationItem>
                    )
                }
            </ModalWrapper>
        </>
    )
}