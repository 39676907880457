import { GET } from './api'

export const Read = async () => {
    return await GET(`/cad-veiculos?_limit=-1`, true, true);
}

export const ReadVehicleByOriginId = async (id) => {
    return await GET(`/cad-veiculos?id_origem=${id}`, true, true);
}

export const ReadStructureByName = async (name) => {
    return await GET(`/vw-estruturas?name=${name}`, true, true);
}
