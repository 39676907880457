import React, { useContext, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentFilterAction,
    ContentFilter,
    ContentTableAction,
    ContentTableActionIcon,
    ContentTableActionIconEdit,
    ContentTableActionIconRemove,
    ContentTableActionIconFile
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Select from "components/Form/Select";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import Input from "components/Form/Input";

export default function DashboardRegisterFounds(){  

    const { setModal } = useContext(CoreContext)

    const { searchExpression, setSearchExpression} = useState('')

    const columns = [
        { title:'Usuário', ref:'name' },
        { title:'E-mail', ref:'email' },
        { title:'Fundo', ref:'found' },
        { title:'Perfil', ref:'profile' },
        { title:'Status', ref:'status' },
        { 
            title: 'Ação', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <Button primary nospace small link onClick={() => null}>
                        <ContentTableActionIconRemove />
                    </Button>
                    <Button primary nospace small link onClick={() => null}>
                        <ContentTableActionIconEdit />
                    </Button>
                </ContentTableAction>
            </>  
        },
    ]
    
    const rows = [
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' }, 
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' }, 
        { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' }, 
    ]


    return ( 
        <>
            <ContainerAuthenticated page={"register/founds"}> 
                <Row>
                    <Col sm={12} >
                        <DashboardTitle>
                            Cadastro fundos
                        </DashboardTitle> 


                        {/* <ContentFilterAction>
                            <ContentFilter>
                                <Input placeholder={"Buscar por"} value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                            </ContentFilter>
                            <ContentTableAction>
                                <Button primary nospace small onClick={() => setModal({ type:'quote' })}>
                                    Novo fundo
                                    <ContentTableActionIcon />
                                </Button>
                            </ContentTableAction>
                        </ContentFilterAction> 

                        <BasicTable columns={columns} rows={rows} /> */}

                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}