import styled from 'styled-components'  

export const SideBackgroundImageContainer = styled.div.attrs({ 
})`          
    background: ${ props => props.theme.palette.primary.main }; 
    min-height:100vh;
    position: fixed;
    width: 58%;

    @media(max-width: 767px){ 
        position: relative;
        min-height:initial; 
        width: 100%;
    }
`;
export const SideBackgroundImage = styled.div.attrs({ 
})`          
    background: white url(/images/login.png) no-repeat center center / cover;
    margin-top: -3px; 
    min-height: calc(100vh + 3px); 
    // mix-blend-mode:color-burn;

    @media(max-width: 767px){ 
        position: absolute;
        height: -webkit-fill-available;
        min-height: 120px; 
        height: 100%;
        width: 100%;
        z-index: 1;
        background: white url(/images/login.png) no-repeat top center / cover;
        margin-top: -10px; 
    }
`;
export const SideBackgroundImageDegree = styled.div.attrs({ 
})`          
    min-height:100vh; 
    background: -moz-linear-gradient(0deg, ${ props => props.theme.palette.primary.main } 17%, ${ props => props.theme.palette.colors.shadow } 60%);
    background: -webkit-linear-gradient(0deg, ${ props => props.theme.palette.primary.main } 17%, ${ props => props.theme.palette.colors.shadow } 60%);
    background: linear-gradient(0deg, ${ props => props.theme.palette.primary.main } 17%, ${ props => props.theme.palette.colors.shadow } 60%);

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:  flex-end;

    @media(max-width: 767px){  
        position: relative;
        min-height:initial; 
        padding-top: 20px;
        z-index: 2;
        min-height: 120px;  
    }
`;
export const FormContent = styled.div.attrs({ 
})`           
    min-height:100vh;
    padding: 70px 5vw;
`;

export const AppLogoDecoration = styled.div.attrs({ 
})`           
    background: ${ props => props.theme.palette.primary.main };
    margin: -70px -42px 0;
    padding: 40px;
    max-width: 50%;
    border-bottom-right-radius: 80%;

    animation-name: sizer;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-iteration-direction: alternate;
    animation-timing-function: ease-in-out;
  
      @keyframes sizer {
          0% {
                padding: 40px;
                margin-bottom: 0;
          }
          50% {
                padding: 40px 80px 80px 40px;
                margin-bottom: -40px;
                margin-right: -5%;
                max-width: 55%;
          }
          100% {
                padding: 40px;
                margin-bottom: 0;
          }
      }


    display: none;          
    @media(max-width: 767px){ 
        display: block;          
    }
`;
export const AppLogo = styled.img.attrs({ 
    src:'/logo1024.png',
    width: 120
})` 
    margin: 0 -60px -60px 0;


`; 

export const Content = styled.div.attrs({ 
})`           
    overflow:hidden;
`; 

export const Touch = styled.div.attrs({ 
})`           
    cursor: pointer;
`; 