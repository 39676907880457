import styledCmp from 'styled-components' 

import { styled } from '@mui/material/styles'; 
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';


export const MaterialAutocomplete = styled(Autocomplete)(({ theme, ...props }) => ({  
    color: props.color ? props.color : 'primary'
}));

export const InputLabelContent = styledCmp.div.attrs({ 
})`
    font-size: 16px;
    font-weight: bolder;
    color: ${ props => props.theme.palette.colors.blackopacity };
    padding-bottom: 0px;
    margin-top: 24px;
    ${
        props => props.outline ? `
            margin-top: 0px;
            padding-bottom: 8px;
        ` : ``
    }
    ${
        props => props.color ? `
            color: red;
        ` : ``
    }
`;