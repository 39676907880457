import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  ["Investment", " ", " "],
  ["Renda fixa", 1000, 0 ],
  ["FIP", 0, 460 ],
  ["FIM", 660, 0 ],
  ["Passivo", 0, 540 ],
  ["despesa", 830, 0 ],
  ["Total", 0, 1030 ],
];

export const options = {
  chart: {
    title: "Perfomance Attribuition",
    subtitle: "Jul/2022",
  },
  colors:['#012E5E', '#D9D9D9'],
  legends: ['none']
};

export function BarsChart() {
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
