import styled from 'styled-components'  
 
import ReactLoading from 'react-loading';


export const UpIcon = styled.img.attrs({ 
    width: 56,
    src:'/icons/upload.svg'
})`
    margin: 20px auto 40px;
`;


export const ModalBody = styled.div.attrs({ 
})`           
    display: flex;
    flex-direction: column;
`;

export const LoadContent = styled.div.attrs({ 
})`           
    max-width: 56px;
    margin: 20px auto 40px;
`;

export const Load = styled(ReactLoading).attrs(props => ({ 
    type:'spinningBubbles',
    color:  props.theme.palette.primary.main,
    height:56,
    width:56
}))`           
`;
  

export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 
    font-weight: bold;
    font-size: 20px; 

    margin: 0px 0 20px;
    text-align:center;
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.grey };
 
    margin-bottom: 10px;
    text-align:center;
`;











export const SideHeader = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export const SideHeaderText = styled.div.attrs({ 
})`
    color: ${ props => props.theme.palette.colors.black }; 
    font-weight: 500;
    font-size: 24px;  
`;
export const SideHeaderIcon = styled.img.attrs({ 
    src:'/icons/close.svg',
    width: 24
})`
    cursor: pointer;
`;


export const NotificationItem = styled.div.attrs({ 
})`
    padding: 16px 0;
    border-bottom: 1px solid ${ props => props.theme.palette.colors.lightgrey };
    cursor: pointer;
    transition: all .3s ease;
    &:hover{
        box-shadow: 0px 1px 1px ${ props => props.theme.palette.colors.lightgrey };
    }
`;
export const NotificationTitle = styled.div.attrs({ 
})`
    margin-bottom: 8px;
    text-transform: uppercase;
    color: ${ props => props.theme.palette.colors.textlight }; 
    font-size: 14px;  
    font-weight: 600;
`;
export const NotificationSubtitle = styled.div.attrs({ 
})`
    margin-bottom: 8px;

    color: ${ props => props.theme.palette.colors.black }; 
    font-weight: 500;
    font-size: 16px;  
`;
export const NotificationText = styled.div.attrs({ 
})` 
    color: ${ props => props.theme.palette.colors.text }; 
    font-size: 16px;  
`;

 