import styled from 'styled-components'  
 
export const ModalContainerOut = styled.div.attrs({ 
})`            
    position: fixed;
    inset: 0 0 0 0;
    background: ${ props => props.theme.palette.colors.modalshadow };
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    ${
        props => props.side ? `

            align-items: initial;
            justify-content: flex-start;
        ` : ``
    }
`;

export const ModalContainer = styled.div.attrs({ 
    className:'modal-in'
})`            
    padding: 18px 32px 28px;
    background: white;
    border-radius: 8px;

    width: 100%;
    max-width: 460px;
    max-height: 94vh;
    overflow: auto;
    
    ${
        props => props.medium ? `
            max-width: 900px;
            border-radius: 8px;
            padding: 0;
        ` : ``
    }
    ${
        props => props.big ? `
            max-width: 1020px;
            border-radius: 8px;
            padding: 0;
            height: 94vh;
        ` : ``
    }
`;