import { CoreContext } from "context/CoreContext";
import React, { useContext, useState } from "react";

import { useHistory } from 'react-router-dom';

import {

    DashboardMenuContainer,
    DashboardMenu,
    DashboardMenuHeader,
    DashboardMenuHeaderIcon,

    DashboardMenuHeaderUserContent,
    DashboardMenuHeaderUserImage,

    DashboardMenuOption,
    DashboardMenuContent,
    DashboardMenuFooter,

    DashboardVersionContent,
    DashboardVersionText,


    DashboardMenuOptionIcon,
    DashboardMenuOptionChevron,
    DashboardMenuOptionText,
    DashboardMenuOptionContent,
    DashboardMenuOptionSuboptions,
    DashboardMenuOptionSuboptionsOption

} from "./styled";


export default function DashboardSide({ opened, setOpened, page }) {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const { user } = useContext(CoreContext)

    const verifyClose = e => {
        if (!e.target.closest('.menu-contant')) {
            setOpened(false)
        }
    }

    const [currentMenu, currentSubmenu] = page?.split('/')

    const [menuOpen, setMenuOpen] = useState([currentMenu || 'investor'])
    const [submenuOpen, setSubmenuOpen] = useState(currentSubmenu || 'balance')

    const optionsSpectra = [
        {
            icon: 'side-3',
            title: 'Cadastro',
            ref: 'register',
            suboptions: [

                { title: 'Uploads', page: 'dashboard/register/uploads', ref: 'uploads' },

                { title: 'Cadastro cotista', page: 'dashboard/register/quotes', ref: 'quotes' },
                { title: 'Cadastro veiculos', page: 'dashboard/register/vehicles', ref: 'vehicles' },

                { title: 'Ent compromissos passivos', page: 'dashboard/register/entry-commitment-passives', ref: 'entry-commitment-passives' },
                { title: 'Ent cotas', page: 'dashboard/register/entry-quotes', ref: 'entry-quotes' },
                { title: 'Ent integralizações', page: 'dashboard/register/entry-integralizations', ref: 'entry-integralizations' },

                // { title:'Cadastro ativos', page:'dashboard/register/actives', ref:'actives' },
                // { title:'Cadastro fundos', page:'dashboard/register/founds', ref:'founds' }
            ]
        },
        {
            icon: 'side-4',
            title: 'Relatórios',
            ref: 'relatedreport',
            suboptions: [
                { title: 'Caixa operacional', page: 'dashboard/relatedreport/operational', ref: 'operational' },
                { title: 'Controle contábil', page: 'dashboard/relatedreport/contabil', ref: 'contabil' },
                { title: 'Chamadas de capital', page: 'dashboard/relatedreport/capital', ref: 'capital' },
                { title: 'Aplicações', page: 'dashboard/relatedreport/applications', ref: 'reapplications' },
                { title: 'Caixa BTG', page: 'dashboard/relatedreport/btg-cash', ref: 'btg-cash' },
            ]
        },
        {
            icon: 'side-5',
            title: 'Usuários',
            ref: 'users',
            suboptions: [
                { title: 'Usuários', page: 'dashboard/users/home', ref: 'home' },
            ]
        },
    ]

    const optionsCommom = [
        {
            icon: 'side-1',
            title: 'Investidor',
            ref: 'investor',
            suboptions: [
                { title: 'Posição Geral', page: 'dashboard/investor/balance', ref: 'balance' },
                user?.spectra ? { title: 'Fluxo de caixa', page: 'dashboard/investor/applications', ref: 'applications' } : null,
                // { title:'Indicadores', page:'dashboard/investor/indicators', ref:'indicators' },
                // { title:'Taxa de retorno', page:'dashboard/investor/payback-rate', ref:'payback-rate' },
            ].filter(fit => fit)
        },
        {
            icon: 'side-2',
            title: 'Reports',
            ref: 'reports',
            suboptions: [
                { title: 'Relatório trimestral', page: 'dashboard/reports/quarter-report', ref: 'quarter-report' },
                { title: 'Carteira mensal', page: 'dashboard/reports/monthly-wallet', ref: 'monthly-wallet' },
                // { title:'Fundos e ativos', page:'dashboard/reports/actives-and-founds', ref:'actives-and-founds' },
                { title: 'Evolução de cotas', page: 'dashboard/reports/quotes-evolutions', ref: 'quotes-evolutions' }
            ]
        },
    ]

    const optionsConfig = [
        {
            big: true,
            icon: 'side-6',
            title: 'Configurações',
            ref: 'config',
            suboptions: [
                { title: 'Reset de senha', page: 'dashboard/reset/password', ref: 'password' },
                { title: 'Meus Dados', page: 'dashboard/config/my-data', ref: 'my-data' },
            ]

        },
    ]

    const options = user?.spectra ? [
        ...optionsCommom,
        ...optionsSpectra,
        ...optionsConfig,
    ] : [
        ...optionsCommom,
        ...optionsConfig
    ];

    const toggleOpen = key => {
        setMenuOpen(
            isOpen(key) ? [...menuOpen.filter((f, k) => f != key)] :
                [...menuOpen, key]
        )
    }

    const isOpen = key => {
        return menuOpen.includes(key)
    }

    const action = async (item) => {
        if (item.page) {
            navigate(item.page)
        }
    }

    return (
        <>
            {
                !opened ? null :
                    <DashboardMenuContainer onClick={verifyClose}>
                        <DashboardMenu>
                            {user && user.id ? (
                                <>
                                    <DashboardMenuHeader onClick={() => setOpened(false)}>
                                        <DashboardMenuHeaderIcon src={user?.image?.url ? user.image.url : '/images/user.png'} />
                                        {user?.name}
                                    </DashboardMenuHeader>
                                    <DashboardMenuContent>
                                        {
                                            options.map((item, key) => <DashboardMenuOptionContent key={key}>
                                                <DashboardMenuOption active={isOpen(item.ref)} onClick={() => toggleOpen(item.ref)}>
                                                    <DashboardMenuOptionIcon icon={item.icon} big={item.big} active={isOpen(item.ref)} />
                                                    <DashboardMenuOptionText>
                                                        {item.title}
                                                    </DashboardMenuOptionText>
                                                    <DashboardMenuOptionChevron active={isOpen(item.ref)} />
                                                </DashboardMenuOption>
                                                {
                                                    isOpen(item.ref) ?
                                                        <DashboardMenuOptionSuboptions>
                                                            {
                                                                item?.suboptions?.map((mit, mik) =>
                                                                    <DashboardMenuOptionSuboptionsOption key={`${key}-${mik}`} active={submenuOpen === mit.ref} onClick={() => action(mit)}>{mit.title}</DashboardMenuOptionSuboptionsOption>
                                                                )
                                                            }
                                                        </DashboardMenuOptionSuboptions>
                                                        : null
                                                }
                                            </DashboardMenuOptionContent>
                                            )
                                        }
                                    </DashboardMenuContent>

                                    <DashboardMenuFooter>
                                        <DashboardVersionContent>
                                            <DashboardVersionText>1.0.0</DashboardVersionText>
                                            <DashboardVersionText>1.10.1.101</DashboardVersionText>
                                        </DashboardVersionContent>
                                    </DashboardMenuFooter>
                                </>
                            ) : null}
                        </DashboardMenu>
                    </DashboardMenuContainer>
            }
        </>
    );
}