import React, { useContext } from 'react'
import { ThemedComponent } from 'ui/theme'
import { CoreContext } from 'context/CoreContext'

import {
    ModalContainerOut,
    ModalContainer
} from './styled'

export default function ModalWrapper({ children, medium, side, big }){

    const { modal, setModal } = useContext(CoreContext)

    const verifyClose = e => {
        // if(!e.target.closest('.modal-in')){
        //     if( modal?.action?.closeable && modal?.action?.action && typeof modal?.action?.action === 'function' ){
        //         modal?.action?.action()
        //     }
        //     close()
        // }
    }

    const close = () => {
        setModal(null)
    }

    return (
        <>
            <ThemedComponent>
                <ModalContainerOut onClick={verifyClose} side={side}>
                    <ModalContainer medium={medium} big={big}>
                        { children }
                    </ModalContainer>
                </ModalContainerOut>
            </ThemedComponent>
        </>
    )
}