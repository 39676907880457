import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentFilterAction,
    ContentFilter,
    ContentTableAction,
    ContentTableActionIcon,
    ContentTableActionIconEdit,
    ContentTableActionIconRemove,
    ContentTableActionIconFile
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Select from "components/Form/Select";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import { Delete, Read } from "services/upload";
import { exposeStrapiError, parseStrapiImage } from "utils";

import moment from 'moment';
import 'moment/locale/pt-br';
import { toast } from "react-toastify";
import Hotjar from "@hotjar/browser";


export default function DashboardUploads(){  

    const { setModal, user } = useContext(CoreContext)

    const edit = row => {
        setModal({ 
            type:'upload', 
            action: () => init(),
            data: row 
        })
    }

    const handleDownloadClick = (row) => {
        if (user) {
            
          const eventData = {
            documentName: row.name, 
            user: {
              id: user.id,
              username: user.username,
              email: user.email, 
            },
          };
    
          Hotjar.event('download', eventData);
        }
    
        window.open(row.link);
      };

    const columns = [
        { 
            title:'Nome', 
            ref:'name',
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <Button primary nospace link small onClick={() => handleDownloadClick(row)}>
                        <ContentTableActionIconFile />
                        Upload
                    </Button>
                </ContentTableAction>
            </>
        },
        { title:'Documento', ref:'document' },
        { title:'Fundo', ref:'fund' },
        { title:'Data upload', ref:'upload_at' },
        { 
            title: ' ', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <Button primary nospace small link onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </Button>
                    <Button primary nospace small link onClick={() => edit(row)}>
                        <ContentTableActionIconEdit />
                    </Button>
                </ContentTableAction>
            </>  
        },
    ]
    
    const [rows, setRows] = useState([ ])
    const [loading, setLoading] = useState(false)

    const remove = async row => {
        setLoading(true)
        const result = await Delete(row?.item?.id)
        if(result && !exposeStrapiError(result)){
            toast.success('Removido com sucesso') ;
            init()
        }else{
            setLoading(false)
        }
    }

    const init = async () => {
        setLoading(true)
        const result = await Read()
        // console.log('ROWS', result)
        
        setRows( !( result && !exposeStrapiError(result)) ? [] : result?.map(mit => ({
            name: mit?.file?.name, document: mit?.document, fund:mit?.cadVeiculo?.veiculo, upload_at: moment(mit.created_at)?.format('L'), link: parseStrapiImage(mit?.file?.url), item:mit
        })))
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])


    return ( 
        <>
            <ContainerAuthenticated page={"register/uploads"}> 
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>
                            Uploads
                        </DashboardTitle> 


                        <ContentFilterAction>
                            <DashboardText>
                                Arquivos Gerados
                            </DashboardText>
                            <ContentTableAction>
                                <div>
                                    <Button primary nospace small onClick={() => setModal({ type:'upload', action: () => init() })}>
                                        Upload
                                        <ContentTableActionIcon />
                                    </Button>
                                    <Button primary small onClick={() => setModal({ type:'upload', many:true, action: () => init() })}>
                                        Upload em massa
                                        <ContentTableActionIcon />
                                    </Button> 
                                </div>
                            </ContentTableAction>
                        </ContentFilterAction>


                        {/* 
                            <DashboardText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut congue nisl. Duis lobortis ante vitae hendrerit efficitur. Quisque dolor magna, efficitur et nunc id, pretium porta mauris. Integer vel lobortis risus. Duis consectetur ac ante at ullamcorper. Nullam luctus sollicitudin odio, eu gravida lorem efficitur ac. Nam pulvinar consequat cursus. Maecenas convallis sagittis erat, at tristique lectus pharetra et. In eget metus nisl. Maecenas orci ipsum, sagittis non erat eleifend, dignissim suscipit mi. Ut a tellus quis enim pellentesque fringilla. Sed in malesuada felis, eget volutpat neque.
                            </DashboardText>  
                        */}

                        <BasicTable loading={loading} columns={columns} rows={rows} />

                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}