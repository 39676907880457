import Input from "components/Form/Input";
import { Form } from "./styled";
import { useState } from "react";
import Button from "components/Form/Button";
import { toast } from "react-toastify";
import { exposeStrapiError } from "utils";
import { ResetPassword } from "services/users";

export default function FormReset() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleResetPassword = (e, passwordRef) => {
    if (passwordRef === 'new-password') { setNewPassword(e.target.value); }
    if (passwordRef === 'confirm-password') { setConfirmPassword(e.target.value); }
  }

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();

    if (newPassword === "" || confirmPassword === "") {
      toast("Por favor, preencha todos os campos.");
      setLoading(false)
      return;
    }

    if (newPassword.length < 6 || confirmPassword.length < 6) {
      toast("A senha deve ter pelo menos 6 caracteres.");
      setLoading(false)
      return;
    }

    if (newPassword !== confirmPassword) {
      toast("Nova senha e confirmar nova senha devem ser iguais.");
      setLoading(false)
      return;
    }

    const body = {
      password: newPassword
    };

    const response = await ResetPassword(body);
    // console.log(response);

    if (!exposeStrapiError(response)) {
      toast("Senha alterada com sucesso!");
    } else {
      toast("Ocorreu um erro ao alterar a senha.");
    }
    setLoading(false)
  }

  const inputFields = [
    { placeholder: "Nova senha", label: "", value: newPassword, passwordRef: 'new-password' },
    { placeholder: "Confirme a nova senha", label: "", value: confirmPassword, passwordRef: 'confirm-password' },
  ];

  return (
    <Form onSubmit={handleSubmit}>

      {inputFields.map((field, index) => (
        <Input
          key={index}
          placeholder={field.placeholder}
          label={field.label}
          onChange={(event) => handleResetPassword(event, field.passwordRef)}
          type="password"
          value={field.value}
        />
      ))}
      <Button type="submit" loading={loading} primary onClick={handleSubmit}>Salvar</Button>

    </Form>
  )
}