import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;



export const ContentFilter = styled.div.attrs({ 
})`            
    max-width: 320px;
    margin-bottom: 32px;
`;


export const ContentTableAction = styled.div.attrs({ 
})`            
    max-width: 60px;
    margin-left: auto;
`;



export const ContentInputRow = styled.div.attrs({ 
})`
    display: flex;
    align-items: center;
    padding: 0 0 12px 0;
    gap: 12px 30px;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export const ContentInputField = styled.div.attrs({ 
})`

    width: 100%;
    max-width: 320px;
    @media(max-width: 1002px){
        max-width: 100%;
    }
`;

export const ContentTableActionIcon = styled.img.attrs({ 
    src:'/icons/expand.svg',
    alt: 'Expand icon'
})`            
`;